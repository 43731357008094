<template>
  <header class="navbar-container">
    <header data-thq="thq-navbar" class="navbar-navbar-interactive">
      <img :alt="logoAlt" :src="logoSrc" class="navbar-image1" />
      <div data-thq="thq-navbar-nav" class="navbar-desktop-menu">
        <nav class="navbar-links1">
          <a class="thq-link thq-body-small" href="#hero">{{ link1 }}</a>
          <a class="thq-link thq-body-small" href="#steps">{{ link2 }}</a>
          <a class="thq-link thq-body-small" href="#features2">{{ link3 }}</a>
          <a class="thq-link thq-body-small" href="#contact">{{ link4 }}</a>
        </nav>
        <div v-if="!isLoggedIn" class="navbar-buttons1">
          <button @click="openLoginModal" class="navbar-action11 thq-button-animated thq-button-filled">
            <span class="thq-body-small">Login</span>
          </button>
          <button @click="openSignupModal" class="navbar-action21 thq-button-outline thq-button-animated">
            <span class="thq-body-small">Sign Up</span>
          </button>

        </div>

        <div v-else class="hamburger-menu">
          <button @click="toggleDropdown" class="hamburger-button">
            <!-- Add hamburger icon here -->
            &#9776;
          </button>
          <div v-if="showDropdown" class="dropdown-menu">
            <button @click="logout" class="dropdown-item">Logout</button>
          </div>
        </div>
      </div>
      <!-- </div> -->



      <div data-thq="thq-burger-menu" class="navbar-burger-menu">
        <svg viewBox="0 0 1024 1024" class="navbar-icon1">
          <path
            d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z">
          </path>
        </svg>
      </div>
      <div data-thq="thq-mobile-menu" class="navbar-mobile-menu">
        <div class="navbar-nav">
          <div class="navbar-top">
            <img :alt="logoAlt" :src="logoSrc" class="navbar-logo" />
            <div data-thq="thq-close-menu" class="navbar-close-menu">
              <svg viewBox="0 0 1024 1024" class="navbar-icon3">
                <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z">
                </path>
              </svg>
            </div>
          </div>
          <nav class="navbar-links2">
            <span class="thq-link thq-body-small">{{ link1 }}</span>
            <span class="thq-link thq-body-small">{{ link2 }}</span>
            <span class="thq-link thq-body-small">{{ link3 }}</span>
            <span class="thq-link thq-body-small">{{ link4 }}</span>
          </nav>
        </div>

        <div v-if="!isLoggedIn" class="navbar-buttons1">
          <button @click="openLoginModal" class="navbar-action11 thq-button-animated thq-button-filled">
            <span class="thq-body-small">Login</span>
          </button>
          <button @click="openSignupModal" class="navbar-action21 thq-button-outline thq-button-animated">
            <span class="thq-body-small">Sign Up</span>
          </button>
        </div>

        <div v-else class="hamburger-menu">
          <button @click="toggleDropdown" class="hamburger-button">
            <!-- Add hamburger icon here -->
            &#9776;
          </button>
          <div v-if="showDropdown" class="dropdown-menu">
            <button @click="logout" class="dropdown-item">Logout</button>
          </div>
        </div>
      </div>
    </header>

    <!-- Login Modal -->
    <login-modal v-if="showLoginModal" :title="'Login'" :fields="loginFields" :buttonText="'Login'"
      :textButtonText="'Forgot Password?'" :handleTextButton="openForgotPasswordModal" :handleSubmit="login"
      @close="closeLoginModal" />



    <!-- Forgot Password Modal -->
    <login-modal v-if="showForgotPasswordModal" :title="'Forgot Password'" :fields="forgotPasswordFields"
      :buttonText="'Request OTP'" :textButtonText="'Already have an account? Login'" :handleTextButton="openLoginModal"
      :handleSubmit="requestOtp" @close="closeForgotPasswordModal" />


    <!-- Reset Password Modal -->
    <login-modal v-if="showResetPasswordModal" :title="'Reset Password'" :fields="resetPasswordFields"
      :buttonText="'Reset Password'" :textButtonText="'Resend OTP'" :handleTextButton="resendOtp"
      :handleSubmit="resetPassword" @close="closeResetPasswordModal" />

    <login-modal v-if="showVerifyModal" :title="'Verify your account'" :fields="verifyFields" :buttonText="'Verify'"
      :textButtonText="'Resend OTP'" :handleTextButton="resendOtp" :handleSubmit="verifyAccount"
      @close="closeVerifyModal" />

    <!-- Signup Modal -->
    <signup-modal v-if="showSignupModal" :title="'Sign Up'" :fields="signupFields" :buttonText="'Sign Up'"
      :handleSubmit="signup" @close="closeSignupModal" />



  </header>
</template>

<script>
import LoginModal from './auth/login.vue';
import SignupModal from './auth/signup.vue';
import AuthMixin from './mixins/authMixin'; // Import the mixin
import logo from '@/assets/logoBG.png';

export default {
  name: 'Navbar',
  components: {
    LoginModal,
    SignupModal,
  },
  mixins: [AuthMixin],
  props: {
    link1: { type: String, default: 'Home' },
    link2: { type: String, default: 'Services' },
    link3: { type: String, default: 'About Us' },
    link4: { type: String, default: 'Contact' },
    logoSrc: { type: String, default: logo },
    logoAlt: { type: String, default: 'PG Company Logo' },
  },
  data() {
    return {
      isLoggedIn: true,
      showDropdown: false,
      showLoginModal: false,
      showSignupModal: false,
      showForgotPasswordModal: false,
      showResetPasswordModal: false,
      showVerifyModal: false,

      loginFields: [
        { name: 'email', type: 'email', placeholder: 'Email', model: '' },
        { name: 'password', type: 'password', placeholder: 'Password', model: '' },
      ],
      forgotPasswordFields: [
        { name: 'email', type: 'email', placeholder: 'Enter your email', model: '' },

      ],
      resetPasswordFields: [
        { name: 'email', type: 'email', placeholder: 'Enter your email', model: '' },
        { name: 'password', type: 'password', placeholder: 'Enter new password', model: '' },
        { name: 'otp', type: 'text', placeholder: 'Enter the OTP sent to your email', model: '' },
      ],
      signupFields: [
        { name: 'fname', type: 'text', placeholder: 'First Name', model: '' },
        { name: 'sname', type: 'text', placeholder: 'Last Name', model: '' },
        { name: 'email', type: 'email', placeholder: 'Email', model: '' },
        { name: 'mobile', type: 'text', placeholder: 'Mobile', model: '' },
        { name: 'password', type: 'password', placeholder: 'Password', model: '' },
      ],
      verifyFields: [
        { email: 'email', type: 'email', placeholder: 'Enter your email', model: '' },
        { v_code: 'v_code', type: 'text', placeholder: 'Enter the OTP sent to your email', model: '' },


      ]
    }
  },
  mounted() {
    // Check if the user is logged in on component mount
    this.checkLoginStatus();
  },
  methods: {


    checkLoginStatus() {
      // Check for token in localStorage
      const token = localStorage.getItem('token');
      this.isLoggedIn = !!token;
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    logout() {
      // Clear the token and update login status
      localStorage.removeItem('token');
      this.isLoggedIn = false;
      this.showDropdown = false;
      alert('Logged out successfully!');
    },
    openLoginModal() {
      this.showLoginModal = true;
    },
    openSignupModal() {
      this.showSignupModal = true;
    },
    openLoginModal() {

      this.showSignupModal = false,
        this.showForgotPasswordModal = false,
        this.showResetPasswordModal = false,
        this.showLoginModal = true
    },
    closeLoginModal() {
      this.showLoginModal = false
    },
    openSignupModal() {
      this.showLoginModal = false,
        this.showForgotPasswordModal = false,
        this.showResetPasswordModal = false,
        this.showSignupModal = true
    },
    closeSignupModal() {
      this.showSignupModal = false
    },
    openForgotPasswordModal() {
      this.showSignupModal = false,
        this.showResetPasswordModal = false,
        this.showLoginModal = false
      this.showForgotPasswordModal = true
    },
    closeForgotPasswordModal() {
      this.showForgotPasswordModal = false
    },
    openResetPasswordModal() {
      this.showSignupModal = false,
        this.showForgotPasswordModal = false,
        this.showLoginModal = false

      this.showResetPasswordModal = true
    },
    closeResetPasswordModal() {
      this.showResetPasswordModal = false
    },
    openVerifyModal() {
      this.showResetPasswordModal = false,
        this.showLoginModal = false,
        this.showSignupModal = false
      this.showVerifyModal = true
    },
    closeVerifyModal() {
      this.showVerifyModal = false
    },
    async login() {

      const credentials = {
        email: this.loginFields.find(field => field.name === 'email').model,
        password: this.loginFields.find(field => field.name === 'password').model,
      };

      try {

        const response = await fetch(process.env.VUE_APP_LOGIN, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(credentials),
        });
        const data = await response.json();
        if (response.ok) {
          alert('Login Successful!');
          localStorage.setItem('token', data.token);
          this.closeLoginModal();
        } else {
          alert(data.error || 'Login failed');
        }
      } catch (error) {
        console.error('Login Error:', error);
      }
    },
    async signup() {
      const credentials = {
        fname: this.signupFields.find(field => field.name === 'fname').model,
        sname: this.signupFields.find(field => field.name === 'sname').model,
        email: this.signupFields.find(field => field.name === 'email').model,
        mobile: this.signupFields.find(field => field.name === 'mobile').model,
        password: this.signupFields.find(field => field.name === 'password').model,
      };
      try {
        const response = await fetch(process.env.VUE_APP_SIGNUP, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(credentials),
        });
        const data = await response.json();
        if (response.ok) {
          alert('Signup Successful!');
          this.closeSignupModal();
          this.openVerifyModal();
        } else {
          alert(data.error || 'Signup failed');
        }
      } catch (error) {
        console.error('Signup Error:', error);
      }
    },


    requestOtp() {
      const emailData = { email: this.forgotPasswordFields.find(field => field.name === 'email').model };
      fetch(process.env.VUE_APP_FORGOT_PASSWORD, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(emailData),
      })
        .then(response => response.json())
        .then(data => {
          if (data.message) {
            alert(data.message);
            this.showForgotPasswordModal = false;
            this.showResetPasswordModal = true;
          } else {
            alert(data.error);
          }
        });
    },

    async verifyAccount() {
      const requestData = {
        email: this.verifyFields.find(field => field.email === 'email').model,
        v_code: this.verifyFields.find(field => field.v_code === 'v_code').model,
      };
      try {
        const response = await fetch(process.env.VUE_APP_VERIFY_ACCOUNT, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestData),
        });
        const data = await response.json();
        if (response.ok) {
          alert(data.message);
          this.showVerifyModal = false;
          this.showLoginModal = true;
        } else {
          alert(data.error);
        }

      } catch (error) {
        alert(error.message);
        console.error('Verify Error:', error);

      }

    },

    // Reset Password API call
    resetPassword() {
      const resetData = {
        email: this.resetPasswordFields.find(field => field.name === 'email').model,
        password: this.resetPasswordFields.find(field => field.name === 'password').model,
        v_code: this.resetPasswordFields.find(field => field.name === 'otp').model,
      };
      fetch(process.env.VUE_APP_RESET_PASSWORD, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(resetData),
      })
        .then(response => response.json())
        .then(data => {
          if (data.message) {
            alert('Password reset successfully');
            this.showResetPasswordModal = false;
          } else {
            alert(data.error);
          }
        });
    },

    // Resend OTP API call
    resendOtp() {
      const emailData = { email: this.forgotPasswordFields.find(field => field.name === 'email').model };
      fetch(process.env.VUE_APP_RESEND_OTP, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(emailData),
      })
        .then(response => response.json())
        .then(data => {
          alert(data.message || data.error);
        });
    },

  }
}
</script>


<style scoped>
.navbar-container {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}

.navbar-navbar-interactive {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}

.navbar-image1 {
  height: 3rem;
}

.navbar-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.navbar-links1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}

.navbar-buttons1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
}

.navbar-action11 {
  display: flex;
  flex-direction: row;
}

.navbar-action21 {
  display: flex;
  flex-direction: row;
}

.navbar-burger-menu {
  display: none;
}

.navbar-icon1 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.navbar-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-light);
}

.navbar-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.navbar-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.navbar-logo {
  height: 3rem;
}

.navbar-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-icon3 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.navbar-links2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.navbar-buttons2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
}

@media(max-width: 767px) {
  .navbar-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .navbar-desktop-menu {
    display: none;
  }

  .navbar-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media(max-width: 479px) {
  .navbar-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }

  .navbar-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}

/* Add styles for the dropdown menu */
.navbar-user-menu {
  position: relative;
}

.hamburger-button {
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  min-width: 120px;
}

.dropdown-item {
  padding: 10px;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
</style>
