import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import NotFound1, { NotFound } from './views/not-found'
//import NotFound from './views/not-found';
import Home from './views/home';
import Services from './components/features1.vue';
import About from './components/steps.vue'; 
import Contact from './components/contact.vue';
import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'Not-Found',
      path: '/not-found',
      component: NotFound1,
    },
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
    {
      name: '404 - Not Found',
      path: '**',
      component: NotFound,
      fallback: true,
    },
    {
      name: 'Contact',
      path: '/contact',
      component: Contact,
    },
    {
      name: 'Services',
      path: '/services',
      component: Services,
    },
    {
      name: 'About',
      path: '/about',
      component: About,
    },
  ],
})
