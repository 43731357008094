<template>
  <div class="hero-header78">
    <div class="hero-column thq-section-padding thq-section-max-width">
      <div class="hero-content1">
        <h1 class="hero-text1 thq-heading-1">{{ heading1 }}</h1>
        <p class="hero-text2 thq-body-large">{{ content1 }}</p>
      </div>
      <div class="hero-actions">
        <button @click="openZipcodeModal" class="thq-button-filled hero-button1">
          <span class="thq-body-small">{{ action1 }}</span>
        </button>
        <button class="thq-button-outline hero-button2">
          <span class="thq-body-small">{{ action2 }}</span>
        </button>
      </div>
      <!-- Zipcode Modal -->
      <zipcode-modal v-if="showZipcodeModal" :submitZipcode="handleZipcodeSubmit" @close="closeZipcodeModal" />

      <!-- <login-modal v-if="showLoginModal" :title="'Login'" :fields="loginFields" :buttonText="'Login'"
      :textButtonText="'Forgot Password?'" :handleTextButton="openForgotPasswordModal" :handleSubmit="login"
      @close="closeLoginModal" />
 -->

      <!-- Items Modal -->
      <items-modal v-if="showItemsModal" :items="items" @addItem="addToCart" @checkout="proceedToCheckout"
        @close="closeItemsModal" />

      <!-- Checkout Modal -->
      <checkout-modal v-if="showCheckoutModal" :pickupDetails="pickupDetails" @submit="openSummaryModal"
        @close="closeCheckoutModal" />


      <!-- Login Modal -->
      <login-modal v-if="showLoginModal" :title="'Login'" :fields="loginFields" :buttonText="'Login'"
        :textButtonText="'Forgot Password?'" :handleTextButton="openForgotPasswordModal" :handleSubmit="login"
        @close="closeLoginModal" />

      <!-- Checkout Summary Modal -->
      <summary-modal v-if="showSummaryModal" :locationData="locationData" :pickupDetails="pickupDetails" :cart="cart"
        @close="closeSummaryModal" @confirmOrder="createOrder" />


         <!-- Forgot Password Modal -->
    <login-modal v-if="showForgotPasswordModal" :title="'Forgot Password'" :fields="forgotPasswordFields"
      :buttonText="'Request OTP'" :textButtonText="'Already have an account? Login'" :handleTextButton="openLoginModal"
      :handleSubmit="requestOtp" @close="closeForgotPasswordModal" />


    <!-- Reset Password Modal -->
    <login-modal v-if="showResetPasswordModal" :title="'Reset Password'" :fields="resetPasswordFields"
      :buttonText="'Reset Password'" :textButtonText="'Resend OTP'" :handleTextButton="resendOtp"
      :handleSubmit="resetPassword" @close="closeResetPasswordModal" />

    <login-modal v-if="showVerifyModal" :title="'Verify your account'" :fields="verifyFields" :buttonText="'Verify'"
      :textButtonText="'Resend OTP'" :handleTextButton="resendOtp" :handleSubmit="verifyAccount"
      @close="closeVerifyModal" />

    <!-- Signup Modal -->
    <signup-modal v-if="showSignupModal" :title="'Sign Up'" :fields="signupFields" :buttonText="'Sign Up'"
      :handleSubmit="signup" @close="closeSignupModal" />







    </div>
    <div class="hero-content2">
      <div class="hero-row-container1 thq-animated-group-container-horizontal thq-mask-image-horizontal">
        <div class="thq-animated-group-horizontal">
          <img :alt="image2Alt" :src="image2Src" class="hero-placeholder-image10 thq-img-scale thq-img-ratio-1-1" />
          <img :alt="image3Alt" :src="image3Src" class="hero-placeholder-image11 thq-img-scale thq-img-ratio-1-1" />
          <img :alt="image6Alt" :src="image6Src" class="hero-placeholder-image12 thq-img-scale thq-img-ratio-1-1" />
        </div>
        <div class="thq-animated-group-horizontal">
          <img :alt="image1Alt" :src="image1Src" class="hero-placeholder-image13 thq-img-scale thq-img-ratio-1-1" />
          <img :alt="image2Alt" :src="image2Src" class="hero-placeholder-image14 thq-img-scale thq-img-ratio-1-1" />
          <img :alt="image3Alt" :src="image3Src" class="hero-placeholder-image15 thq-img-scale thq-img-ratio-1-1" />
          <img :alt="image4Alt" :src="image4Src" class="hero-placeholder-image16 thq-img-scale thq-img-ratio-1-1" />
          <img :alt="image5Alt" :src="image5Src" class="hero-placeholder-image17 thq-img-scale thq-img-ratio-1-1" />
          <!-- <img alt="Hero Image"
            src="https://images.unsplash.com/photo-1534312527009-56c7016453e6?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIxfHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&amp;ixlib=rb-4.0.3&amp;w=1500"
            class="hero-placeholder-image18 thq-img-scale thq-img-ratio-1-1" /> -->
        </div>
      </div>

      <div class="hero-row-container2 thq-animated-group-container-horizontal thq-mask-image-horizontal">
        <div class="thq-animated-group-horizontal-reverse">
          <img :alt="image9Alt" :src="image9Src" class="hero-placeholder-image19 thq-img-scale thq-img-ratio-1-1" />
          <img :alt="image10Alt" :src="image10Src" class="hero-placeholder-image20 thq-img-scale thq-img-ratio-1-1" />
          <img :alt="image11Alt" :src="image11Src" class="hero-placeholder-image21 thq-img-scale thq-img-ratio-1-1" />
          <img :alt="image12Alt" :src="image12Src" class="hero-placeholder-image22 thq-img-scale thq-img-ratio-1-1" />
        </div>
        <div class="thq-animated-group-horizontal-reverse">
          <img :alt="image7Alt" :src="image7Src" class="hero-placeholder-image23 thq-img-scale thq-img-ratio-1-1" />
          <img :alt="image8Alt" :src="image8Src" class="hero-placeholder-image24 thq-img-scale thq-img-ratio-1-1" />
          <img :alt="image9Alt" :src="image9Src" class="hero-placeholder-image25 thq-img-scale thq-img-ratio-1-1" />
          <img :alt="image10Alt" :src="image10Src" class="hero-placeholder-image26 thq-img-scale thq-img-ratio-1-1" />
          <img :alt="image11Alt" :src="image11Src" class="hero-placeholder-image27 thq-img-scale thq-img-ratio-1-1" />
          <!-- <img alt="Hero Image"
            src="https://images.unsplash.com/photo-1568214379698-8aeb8c6c6ac8?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEyfHxncmFmaWN8ZW58MHx8fHwxNzE1Nzk0OTk5fDA&amp;ixlib=rb-4.0.3&amp;w=1500"
            class="hero-placeholder-image28 thq-img-scale thq-img-ratio-1-1" /> -->
        </div>
      </div>
    </div>
    <div>
      <div class="hero-container2">
        <DangerousHTML html="<style>
    @keyframes scroll-x {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(calc(-100% - 16px));
      }
    }
  
    @keyframes scroll-y {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(calc(-100% - 16px));
      }
    }
  </style>
  "></DangerousHTML>
      </div>
    </div>


  </div>
</template>

<script>
import ZipcodeModal from './order/zipcode.vue'
import CheckoutModal from './order/checkout.vue'
import ItemsModal from './order/items.vue'
import DangerousHTML from 'dangerous-html/vue'
import axios from 'axios';
import LoginModal from './auth/login.vue'
import SignupModal from './auth/signup.vue'
import SummaryModal from './order/summary.vue'
import AuthMixin from './mixins/authMixin';





export default {
  name: 'Hero',
  components: {
    DangerousHTML,
    ZipcodeModal,
    CheckoutModal,
    ItemsModal,
    LoginModal,
    SignupModal,
    SummaryModal,

  },
  mixins: [AuthMixin],
  data() {
    return {
      showZipcodeModal: false,
      showItemsModal: false,
      showCheckoutModal: false,
      showSummaryModal: false,

      items: [],
      pickupDetails: {
        house_no: '',
        street_address: '',
        pickup_datetime: '',
        contact_mobile: ''
      },
      cart: [], // Holds the added items
      locationData: null,
    };
  },
  methods: {
    openZipcodeModal() {
      this.showZipcodeModal = true;
    },
    closeZipcodeModal() {
      this.showZipcodeModal = false;
    },
    closeCheckoutModal() {
      this.showCheckoutModal = false;
      this.showItemsModal = false;
      this.showZipcodeModal = false;
    },
    openItemsModal() {
      this.showItemsModal = true;
    },
    closeItemsModal() {
      this.showItemsModal = false;
    },
    openSummaryModal() {
      this.showCheckoutModal = false;
      this.showSummaryModal = true;
    },
    async handleZipcodeSubmit(zipcode) {
      try {
        // Fetch location data
        const locationResponse = await axios.get(`${process.env.VUE_APP_ZIPCODE}${zipcode}`);
        this.locationData = locationResponse.data;
        if (!this.locationData) {
          throw new Error("No location found for this zipcode.");
        }

        // Fetch service locations based on the location data
        const serviceLocationResponse = await axios.post(process.env.VUE_APP_SERVICE_LOCATIONS, {
          location_name: this.locationData.city
        });
        const serviceLocation = serviceLocationResponse.data;

        if (!serviceLocation || serviceLocation.length === 0) {
          throw new Error("No service location found for this zipcode.");
        }

        // Fetch items based on the service location
        const itemsResponse = await axios.post(process.env.VUE_APP_ITEMS, {
          location_id: serviceLocation[0].id
        });
        this.items = itemsResponse.data;

        if (!this.items || this.items.length === 0) {
          throw new Error("No items found for this zipcode.");
        }

        // Close zipcode modal and show items modal
        this.closeZipcodeModal();
        this.showItemsModal = true;
      } catch (error) {
        // Log the original error for debugging
        console.error('Error fetching location, service location, or items:', error);

        // Throw a user-friendly error message
        throw new Error("There was a problem processing your request. Please try a different zipcode.");
      }
    }
    ,

    addToCart(selectedItems) {
      selectedItems.forEach(newItem => {
        const existingItem = this.cart.find(cartItem => cartItem.item_id === newItem.id);
        if (existingItem) {
          existingItem.quantity = newItem.quantity;
        } else {
          this.cart.push({
            item_id: newItem.id,
            quantity: newItem.quantity,
            name: newItem.name
            // Add item name to cart
          });
        }
      });
    },

    proceedToCheckout(selectedItems) {
      if (!localStorage.getItem('token')) {
        alert('Please log in to checkout.');
        this.showLoginModal = true;
        return;
      }

      this.addToCart(selectedItems); // Ensure items are added to the cart
      this.showItemsModal = false;
      this.showCheckoutModal = true;
    },

    // proceedToCheckout(selectedItems) {
    //   if (!localStorage.getItem('token')) {
    //     alert('Please log in to checkout.');
    //     this.showLoginModal = true; // Show login modal if not logged in
    //     return;
    //   }

    //   this.cart = selectedItems.map(item => ({
    //     item_id: item.id,
    //     quantity: item.quantity,
    //     name: item.name // Added name for the summary display
    //   }));

    //   this.showItemsModal = false;
    //   this.showSummaryModal = true; // Show summary modal before final checkout
    // },

    closeSummaryModal() {
      this.showSummaryModal = false;
    },


    async createOrder() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('Please log in to place an order.');
        this.showLoginModal = true;
        return;
      }

      try {
        const response = await axios.post(process.env.VUE_APP_ORDER, {
          collector_id: 2,
          longitude: String(this.locationData.longitude),
          latitude: String(this.locationData.latitude),
          ...this.pickupDetails,
          order_items: this.cart.map(item => ({
            item_id: item.item_id,
            quantity: item.quantity
          }))
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`
          }
        });

        alert(response.data.message);
        this.showSummaryModal = false;
      } catch (error) {
        console.error('Order creation failed:', error);
      }
    }

  },


  props: {
    image3Src: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1523210299746-f23a7af1a413?q=80&w=2880&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    image8Alt: {
      type: String,
      default: 'Hero Image',
    },
    image2Src: {
      type: String,
      default:
        'https://img.freepik.com/free-vector/trash-pickup-worker-cleaning-dustbin-truck-man-carrying-trash-plastic-bag-flat-vector-illustration-city-service-waste-disposal-concept_74855-10181.jpg?t=st=1726998935~exp=1727002535~hmac=dee80e20d94a0ec0664fe5b7ea43ada7cc1f1d94b6f6dc152d890726ec8d66f8&amp;w=2000',
    },
    image6Alt: {
      type: String,
      default: 'Hero Image',
    },
    image11Src: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1523210299746-f23a7af1a413?q=80&w=2880&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    image5Alt: {
      type: String,
      default: 'Hero Image',
    },
    image1Alt: {
      type: String,
      default: 'Garbage Truck Collecting Waste',
    },
    image7Src: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1515694207715-2046efc1968d?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    image7Alt: {
      type: String,
      default: 'Hero Image',
    },
    image12Alt: {
      type: String,
      default: 'Hero Image',
    },
    image2Alt: {
      type: String,
      default: 'Hero Image',
    },
    image6Src: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1515694207715-2046efc1968d?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    image12Src: {
      type: String,
      default:
        'https://img.freepik.com/free-vector/trash-pickup-worker-cleaning-dustbin-truck-man-carrying-trash-plastic-bag-flat-vector-illustration-city-service-waste-disposal-concept_74855-10181.jpg?t=st=1726998935~exp=1727002535~hmac=dee80e20d94a0ec0664fe5b7ea43ada7cc1f1d94b6f6dc152d890726ec8d66f8&w=2000',
    },
    image3Alt: {
      type: String,
      default: 'Hero Image',
    },
    image9Src: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1515694207715-2046efc1968d?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    image11Alt: {
      type: String,
      default: 'Hero Image',
    },
    action2: {
      type: String,
      default: 'Learn More',
    },
    action1: {
      type: String,
      default: 'Get a Quote',
    },
    image8Src: {
      type: String,
      default:
        'https://plus.unsplash.com/premium_photo-1661290296426-d55fd01740c1?q=80&w=2942&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    image5Src: {
      type: String,
      default:
        'https://plus.unsplash.com/premium_photo-1661290296426-d55fd01740c1?q=80&w=2942&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    image4Src: {
      type: String,
      default:
        'https://img.freepik.com/free-photo/scene-with-miscellaneous-items-being-sold-yard-sale-bargains_23-2151216827.jpg?t=st=1727012119~exp=1727015719~hmac=f84ca26b6cbd6c26fda0a07effa5846fbb4f537acddbd435e3aa14b6eee65956&w=2000',
    },
    image10Alt: {
      type: String,
      default: 'Hero Image',
    },
    image4Alt: {
      type: String,
      default: 'Hero Image',
    },
    heading1: {
      type: String,
      default: 'Reliable Junk Removal Services',
    },
    content1: {
      type: String,
      default:
        'We offer professional and efficient junk removal services to help you declutter your space and maintain a clean, organized environment.',
    },
    image10Src: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1668955254766-1bb2de25cf16?q=80&w=3113&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    image9Alt: {
      type: String,
      default: 'Hero Image',
    },
    image1Src: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1515694207715-2046efc1968d?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
  },


}
</script>

<style scoped>
.hero-header78 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}

.hero-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}

.hero-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}

.hero-text1 {
  text-align: center;
}

.hero-text2 {
  text-align: center;
}

.hero-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
}

.hero-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}

.hero-row-container1 {
  width: 100%;
}

.hero-placeholder-image10 {
  width: 400px;
  height: 400px;
}

.hero-placeholder-image11 {
  width: 400px;
  height: 400px;
}

.hero-placeholder-image12 {
  width: 400px;
  height: 400px;
}

.hero-placeholder-image13 {
  width: 400px;
  height: 400px;
}

.hero-placeholder-image14 {
  width: 400px;
  height: 400px;
}

.hero-placeholder-image15 {
  width: 400px;
  height: 400px;
}

.hero-placeholder-image16 {
  width: 400px;
  height: 400px;
}

.hero-placeholder-image17 {
  width: 400px;
  height: 400px;
}

.hero-placeholder-image18 {
  width: 400px;
  height: 400px;
}

.hero-row-container2 {
  width: 100%;
}

.hero-placeholder-image19 {
  width: 400px;
  height: 400px;
}

.hero-placeholder-image20 {
  width: 400px;
  height: 400px;
}

.hero-placeholder-image21 {
  width: 400px;
  height: 400px;
}

.hero-placeholder-image22 {
  width: 400px;
  height: 400px;
}

.hero-placeholder-image23 {
  width: 400px;
  height: 400px;
}

.hero-placeholder-image24 {
  width: 400px;
  height: 400px;
}

.hero-placeholder-image25 {
  width: 400px;
  height: 400px;
}

.hero-placeholder-image26 {
  width: 400px;
  height: 400px;
}

.hero-placeholder-image27 {
  width: 400px;
  height: 400px;
}

.hero-placeholder-image28 {
  width: 400px;
  height: 400px;
}

.hero-container2 {
  display: contents;
}

@media(max-width: 767px) {
  .hero-content2 {
    width: 100%;
  }
}

@media(max-width: 479px) {
  .hero-actions {
    width: 100%;
    flex-direction: column;
  }

  .hero-button1 {
    width: 100%;
  }

  .hero-button2 {
    width: 100%;
  }
}
</style>
